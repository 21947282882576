import type { FC } from 'react';
import React from 'react';

import {
	VIEW_PAGE_FAVORITE_BUTTON_EXPERIENCE,
	ExperienceStart,
} from '@confluence/experience-tracker';
import {
	PageSegmentLoadStart,
	SSRMouseEventWrapper,
	SSR_FAVORITE_BUTTON_METRIC,
} from '@confluence/browser-metrics';
import { Attribution, ErrorBoundary } from '@confluence/error-boundary';
import { useSessionData } from '@confluence/session-data';

import { FavouriteButtonComponent } from './FavouriteButtonComponent';
import { FAVOURITE_BUTTON_METRIC } from './perf.config';

type FavouriteButtonProps = {
	contentId: string;
	isDisabled?: boolean;
};

export const FavouriteButton: FC<FavouriteButtonProps> = ({ contentId, isDisabled }) => {
	const { isLoggedIn } = useSessionData();

	if (!isLoggedIn) {
		return null;
	}

	return (
		<ErrorBoundary attribution={Attribution.DISCO}>
			<PageSegmentLoadStart key={contentId} metric={FAVOURITE_BUTTON_METRIC} />
			<ExperienceStart id={contentId} name={VIEW_PAGE_FAVORITE_BUTTON_EXPERIENCE} />
			<SSRMouseEventWrapper metricName={SSR_FAVORITE_BUTTON_METRIC}>
				<FavouriteButtonComponent contentId={contentId} isDisabled={isDisabled} />
			</SSRMouseEventWrapper>
		</ErrorBoundary>
	);
};
