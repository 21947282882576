import type { FC } from 'react';
import React, { useEffect } from 'react';
import { useQuery } from 'react-apollo';
import { styled } from '@compiled/react';

import {
	VIEW_PAGE_FAVORITE_BUTTON_EXPERIENCE,
	ExperienceSuccess,
	ExperienceFailure,
} from '@confluence/experience-tracker';
import { ErrorDisplay } from '@confluence/error-boundary';
import { PageSegmentLoadEnd } from '@confluence/browser-metrics';
import { PageStarButton } from '@confluence/action-buttons/entry-points/pageStar';
import { STAR_BUTTON_SHORTCUT, STAR_BUTTON_SHORTCUT_IN_EDIT } from '@confluence/shortcuts';
import { fg } from '@confluence/feature-gating';
import { useLivePageMode } from '@confluence/live-pages-utils/entry-points/useLivePagesStore';
import { useIsEditorPage } from '@confluence/route-manager/entry-points/useIsEditorPage';

import { FavouriteButtonPlaceholder } from './FavouriteButtonPlaceholder';
import { FavouriteButtonQuery } from './FavouriteButtonQuery.graphql';
import { FAVOURITE_BUTTON_METRIC } from './perf.config';
import type {
	FavouriteButtonQuery as QueryData,
	FavouriteButtonQueryVariables as QueryVars,
} from './__types__/FavouriteButtonQuery';

type FavouriteButtonComponentProps = {
	contentId: string;
	onRender?: () => void;
	isDisabled?: boolean;
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ButtonSpacer = styled.div({
	minWidth: '30px',
});

export const FavouriteButtonComponent: FC<FavouriteButtonComponentProps> = ({
	contentId,
	onRender,
	isDisabled,
}) => {
	const [{ isEditMode: isLiveEditMode }] = useLivePageMode();
	const isOnEditRoute = useIsEditorPage();

	const { data, loading, error } = useQuery<QueryData, QueryVars>(
		// eslint-disable-next-line graphql-relay-compat/no-import-graphql-operations -- Read https://go/connie-relay-migration-fyi
		FavouriteButtonQuery,
		{
			variables: {
				contentId,
			},
			fetchPolicy: 'cache-first',
		},
	);

	const contentType = data?.singleContent?.type;

	const hasReadPermission = Boolean(
		data?.contentOperations?.operations?.filter(
			(operation) => operation?.targetType === contentType && operation?.operation === 'read',
		).length,
	);

	useEffect(() => {
		if (onRender && data) {
			onRender();
		}
	});

	if (error) {
		return (
			<>
				<ExperienceFailure name={VIEW_PAGE_FAVORITE_BUTTON_EXPERIENCE} error={error} />
				<ErrorDisplay error={error} />
			</>
		);
	}

	if (loading) {
		return <FavouriteButtonPlaceholder />;
	}

	const isStarred = Boolean(data?.singleContent?.metadata.currentuser?.favourited?.isFavourite);

	return (
		<>
			{hasReadPermission && (
				<ButtonSpacer>
					<PageStarButton
						contentId={contentId}
						isStarred={isStarred}
						shortcut={
							isLiveEditMode ||
							(isOnEditRoute && fg('confluence_frontend_edit_page_keyboard_shortcut'))
								? STAR_BUTTON_SHORTCUT_IN_EDIT
								: STAR_BUTTON_SHORTCUT
						}
						tooltipPosition="bottom"
						analytics={{
							source: 'FavouriteButton',
							attributes: { componentVersion: 'v3', pageType: contentType },
						}}
						isDisabled={isDisabled}
					/>
				</ButtonSpacer>
			)}
			<ExperienceSuccess
				name={VIEW_PAGE_FAVORITE_BUTTON_EXPERIENCE}
				attributes={{
					isFavouritedByCurrentUser: isStarred,
				}}
			/>
			<PageSegmentLoadEnd
				key={contentId}
				metric={FAVOURITE_BUTTON_METRIC}
				customData={{ isVisible: hasReadPermission }}
			/>
		</>
	);
};
