import type { FC, MouseEvent, KeyboardEvent } from 'react';
import React, { useCallback } from 'react';
import { defineMessages, useIntl } from 'react-intl-next';

import type { PositionType } from '@atlaskit/tooltip';
import Tooltip from '@atlaskit/tooltip';
import { useAnalyticsEvents } from '@atlaskit/analytics-next';

import {
	GeneralShortcutListener,
	ShortcutVisualizer,
	STAR_BUTTON_SHORTCUT,
	STAR_BUTTON_SHORTCUT_IN_EDIT,
} from '@confluence/shortcuts';
import { useLivePageMode } from '@confluence/live-pages-utils/entry-points/useLivePagesStore';
import type { ShortcutEvent } from '@confluence/shortcuts';
import { useIsEditorPage } from '@confluence/route-manager/entry-points/useIsEditorPage';

import { ConditionalHiddenAction, ConditionalSubtleHoverButton } from '../PresentationalComponents';
import { StarIcon } from '../StarIcon';

import type { PageStarProps } from './PageStar';
import { PageStar } from './PageStar';

export type ButtonProps = {
	size?: 'small' | 'medium';
	outlineAppearance?: 'normal' | 'subtle';
	tooltipPosition?: PositionType;
	shortcut?: string;
	label?: string;
	labelledBy?: string;
	id?: string;
	isDisabled?: boolean;
};

export const PageStarButton: FC<ButtonProps & PageStarProps> = ({
	size,
	outlineAppearance,
	tooltipPosition,
	shortcut,
	label,
	labelledBy,
	id,
	...props
}) => {
	const intl = useIntl();
	const [{ isEditMode: isLiveEditMode }] = useLivePageMode();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const isOnEditRoute = useIsEditorPage();

	const spacing = size === 'small' ? 'compact' : 'default';
	const tooltipLabel = props.isStarred
		? intl.formatMessage(i18n.unstarContent)
		: intl.formatMessage(i18n.starContent);
	const tooltipContent = shortcut ? (
		<ShortcutVisualizer shortcut={shortcut} contentBefore={tooltipLabel} />
	) : (
		tooltipLabel
	);
	const buttonLabel = props.isStarred
		? intl.formatMessage(i18n.unstarContent)
		: intl.formatMessage(i18n.starContent);

	const handleClick = useCallback(
		(starHandler, triggeredByKeyboardShortcut) =>
			(e: MouseEvent | KeyboardEvent | ShortcutEvent) => {
				starHandler(e);
				createAnalyticsEvent({
					type: 'sendUIEvent',
					data: {
						action: 'clicked',
						actionSubject: 'button',
						actionSubjectId: 'pageStar',
						attributes: {
							triggeredByKeyboardShortcut,
							action: props.isStarred ? 'unstar' : 'star',
						},
						source: 'PageStarButton',
					},
				}).fire();
			},
		[createAnalyticsEvent, props.isStarred],
	);

	return (
		<PageStar {...props}>
			{({ toggle: toggleStar }) => (
				<>
					<Tooltip content={tooltipContent} position={tooltipPosition || 'top'} hideTooltipOnClick>
						<ConditionalHiddenAction isHidden={!props.isStarred}>
							<ConditionalSubtleHoverButton
								id={id}
								isSubtle={outlineAppearance === 'subtle'}
								data-id={props.isStarred ? 'page-unstar-button' : 'page-star-button'}
								testId={props.isStarred ? 'page-unstar-button' : 'page-star-button'}
								spacing={spacing}
								appearance="subtle"
								onClick={handleClick(toggleStar, false)}
								iconBefore={<StarIcon isStarred={props.isStarred} spacing={props.spacing} />}
								aria-label={buttonLabel}
								aria-labelledby={labelledBy}
								aria-pressed={props.isStarred}
								isDisabled={props.isDisabled}
							/>
						</ConditionalHiddenAction>
					</Tooltip>
					{shortcut && (
						<GeneralShortcutListener
							accelerator={
								isLiveEditMode || isOnEditRoute
									? STAR_BUTTON_SHORTCUT_IN_EDIT
									: STAR_BUTTON_SHORTCUT
							}
							listener={handleClick(toggleStar, true)}
						/>
					)}
				</>
			)}
		</PageStar>
	);
};

const i18n = defineMessages({
	starContent: {
		id: 'action-buttons.content.starContent.label',
		defaultMessage: 'Star this content',
		description:
			'Label for unfilled star icon button which indicates that the content is unstarred & stars the content when clicked',
	},
	unstarContent: {
		id: 'action-buttons.content.unstarContent.label',
		defaultMessage: 'Unstar this content',
		description:
			'Label for filled star icon button which indicates that the content is starred & unstars the content when clicked',
	},
});
